

















import { computed, defineComponent, PropType } from '@vue/composition-api';
import { Branch, BranchState } from '@/model/store';
import AppTimeAgo from '@/components/shared/TimeAgo.vue';
import { useI18n } from 'vue-i18n-bridge';
import { BranchDeploymentStateType } from 'ionos-space-api-v4';

export default defineComponent({
  name: 'AppBranchDeploymentState',
  components: { AppTimeAgo },
  props: {
    branch: {
      type: Object as PropType<Branch>,
      required: true,
    },
    projectDeleted: Boolean,
  },
  setup(props) {
    const { t } = useI18n();

    const labelAndIcon = computed<{ label: string; icon: string }>(() => {
      if (props.projectDeleted) {
        return {
          label: t('application.project.branch.deploymentState.PROJECT_ON_GITHUB_DELETE'),
          icon: 'mdi-alert-circle-outline',
        };
      } else if (props.branch.deleted) {
        return {
          label: t('application.project.branch.buildState.ON_GITHUB_DELETE'),
          icon: 'mdi-alert-circle-outline',
        };
      }

      if (props.branch.buildState?.state === BranchState.SUCCESS) {
        if (props.branch.deploymentState?.state) {
          const label = t(`application.project.branch.deploymentState.${props.branch.deploymentState?.state}`);
          let icon = '';
          switch (props.branch.deploymentState.state) {
            case BranchDeploymentStateType.RUNNING:
              icon = 'mdi-autorenew';
              break;
            case BranchDeploymentStateType.FAILED:
              icon = 'mdi-alert-circle-outline';
              break;
            case BranchDeploymentStateType.PARTLY_FAILED:
              icon = 'mdi-alert-outline';
              break;
            case BranchDeploymentStateType.SUCCESS:
              icon = 'mdi-check-circle-outline';
              break;
          }
          return {
            label,
            icon,
          };
        }
      } else if (props.branch.buildState?.state) {
        const label = t(`application.project.branch.buildState.${props.branch.buildState.state}`);
        let icon = '';
        switch (props.branch.buildState.state) {
          case BranchState.INITIAL_BUILD_FAILED:
          case BranchState.FAILED:
          case BranchState.UNKNOWN:
            icon = 'mdi-alert-circle-outline';
            break;
          case BranchState.RUNNING:
            icon = 'mdi-autorenew';
            break;
          case BranchState.AWAITING_BUILD:
            icon = 'mdi-timer-sand-empty';
            break;
        }
        return {
          label,
          icon,
        };
      }

      return {
        label: '',
        icon: '',
      };
    });

    const color = computed<string>(() => {
      switch (labelAndIcon.value.icon) {
        case 'mdi-alert-circle-outline':
          return 'error';
        case 'mdi-check-circle-outline':
          return 'success';
        case 'mdi-alert-outline':
          return 'warning';
        default:
          return 'info';
      }
    });

    const lastChangedDate = computed<Date | undefined>(() => {
      if (props.branch.deleted || props.projectDeleted) {
        return undefined;
      }
      if (props.branch.buildState?.state === BranchState.SUCCESS) {
        return props.branch.deploymentState?.lastDeploymentDate;
      }
      return props.branch.buildState?.lastBuildDate;
    });

    const label = computed<string>(() => {
      return labelAndIcon.value.label;
    });

    const icon = computed<string>(() => {
      return labelAndIcon.value.icon;
    });

    return {
      label,
      icon,
      lastChangedDate,
      color,
    };
  },
});
