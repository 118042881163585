













import { defineComponent, PropType } from '@vue/composition-api';
import { Project } from '@/model/store';
import { useGitHubStore } from '@/stores/github';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'AppProjectStateChip',
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup() {
    const { getInstallationUrl } = storeToRefs(useGitHubStore());

    return {
      restorePermissionUrl: getInstallationUrl,
    };
  },
});
