












import { defineComponent, PropType } from '@vue/composition-api';
import { Project } from '@/model/store';
import AppProjectsItem from '@/components/dashboard/ProjectsItem.vue';

export default defineComponent({
  name: 'AppProjects',
  components: { AppProjectsItem },
  props: {
    projects: {
      type: Array as PropType<Project[]>,
      required: true,
    },
  },
  emits: ['select'],
});
