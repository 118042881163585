

















import { defineComponent } from '@vue/composition-api';
import { ProjectSortField } from '@/model/store';
import { useProjectsStore } from '@/stores/projects';

export default defineComponent({
  name: 'AppSortSelect',
  setup() {
    const projectsStore = useProjectsStore();

    const onInput = (sort: ProjectSortField) => {
      if (sort) {
        projectsStore.sortProjects(sort);
      }
    };

    return {
      items: [
        ProjectSortField.lastDeploymentDateDesc,
        ProjectSortField.lastDeploymentDateAsc,
        ProjectSortField.createdDateDesc,
        ProjectSortField.createdDateAsc,
      ],
      projectsStore,
      onInput,
    };
  },
});
