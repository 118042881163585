


































import { computed, defineComponent } from '@vue/composition-api';
import AppProjects from '@/components/dashboard/Projects.vue';
import AppGithubInstallationStatusPanel from '@/components/shared/GithubInstallationStatusPanel.vue';
import AppSortSelect from '@/components/dashboard/SortSelect.vue';
import { useProjectsStore } from '@/stores/projects';
import { useAccountInfoStore } from '@/stores/account-info';
import { PROJECT_DETAIL, SETUP_REPOSITORY } from '@/utils/const';

export default defineComponent({
  name: 'Dashboard',
  components: {
    AppGithubInstallationStatusPanel,
    AppProjects,
    AppSortSelect,
  },
  props: {
    isSetup: Boolean,
  },
  setup(props, { root }) {
    const router = root.$router;
    const projectsStore = useProjectsStore();
    const accountInfoStore = useAccountInfoStore();

    projectsStore.loadListOfProjects().then(() => {
      if (!projectsStore.projects.length) {
        router.push({ name: SETUP_REPOSITORY });
      }
    });

    const feedbackTileVisible = computed<boolean>(() => {
      return accountInfoStore.showFeedbackTile;
    });

    function openDetails(project) {
      router.push({ name: PROJECT_DETAIL, params: { projectId: project.id } });
    }

    function clickFeedbackTile() {
      const accountInfoStore = useAccountInfoStore();
      accountInfoStore.closeFeedbackTile();
    }

    return {
      projectsStore,
      feedbackTileVisible,
      openDetails,
      clickFeedbackTile,
    };
  },
});
