












































import { defineComponent, computed, PropType } from '@vue/composition-api';
import { useProjectsStore } from '@/stores/projects';
import { Project } from '@/model/store';
import AppProjectStateChip from '@/components/shared/project/ProjectStateChip.vue';
import AppScreenshotImage from '@/components/shared/ScreenshotImage.vue';
import { storeToRefs } from 'pinia';
import AppBranchDeploymentState from '@/components/shared/BranchDeploymentState.vue';
import { urlWithHttpsProtocol } from '@/utils';

export default defineComponent({
  name: 'AppProjectsItem',
  components: { AppBranchDeploymentState, AppScreenshotImage, AppProjectStateChip },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  emits: ['click'],
  setup(props) {
    const { projectGithubUrl } = storeToRefs(useProjectsStore());

    const name = computed<string>(() => {
      return props.project.name;
    });
    const domainUrl = computed<string>(() => {
      return urlWithHttpsProtocol(props.project._projectDomain);
    });
    const projectType = computed<string>(() => {
      return props.project.projectType;
    });

    return {
      name,
      domainUrl,
      projectType,
      projectGithubUrl,
    };
  },
});
